import React from "react";

export class COVID19Banner extends React.Component {
  render() {
    window.scrollTo(0, 0);
    return (
      <div className="covid19Banner">
        <h4>Be A Voice! Share your message <a href="https://www.beavoice.anniecannons.org/" rel="noopener noreferrer" target="_blank">here</a>. </h4>
      </div>
    )
  }
}